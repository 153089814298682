import { Link } from "gatsby"
import React, { useEffect } from "react"
import Layout from "../../components/layout"
import "./../../styles/terms-and-conditions.css"

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="about-us-section-container">
        <div className="container-heading">Terms & Conditions</div>
        <div className="terms-content-container">
          <div className="terms-section-container">
            <p className="terms-content-text" style={{ textAlign: "justify" }}>
              You are required to follow certain rules while you use our
              Platform. We have listed these rules in the Terms. Please read
              these Terms and other hyperlinks mentioned here carefully. Do
              remember that by using our Platform, you agree to these Terms.
            </p>
            <p className="terms-content-text">
              These terms of use (“
              <span className="terms-text-bold ">Terms</span> ”) govern your use
              of our website:{" "}
              <Link to="https://mooo.farm/">
                <span style={{ color: "blue", textDecoration: "underline" }}>
                  https://mooo.farm/
                </span>{" "}
              </Link>{" "}
              and our mobile application MoooFarm Farmer (each, the{" "}
              <span className="terms-text-bold ">“Platform”</span>) which are
              operated by MoooFarm Pvt. Ltd. (“
              <span className="terms-text-bold ">Company</span>”, “
              <span className="terms-text-bold ">we</span>”, “
              <span className="terms-text-bold ">our</span>” or “
              <span className="terms-text-bold ">us</span>”) incorporated under
              Indian Companies Act, 2013 with its registered office at WeWork
              32nd Milestone, Galaxy Hotel, NH-8, Sector 15 Part 2, Gurugram,
              Haryana 122001 - India. Any reference to “
              <span className="terms-text-bold ">you</span>” or “
              <span className="terms-text-bold ">your</span>” refers to you as a
              user of the Platform. We reserve the right to assign our rights
              and obligations under these Terms.
            </p>
            <p className="terms-content-text">
              Please ensure that you read these Terms carefully as they
              constitute a binding agreement between you and us. If you do not
              agree to these Terms, please do not use the Platform. We reserve
              the right to revise these Terms, update the Platform and/or change
              the Content at any time. Please check this page from time to time
              to take notice of any changes we made, as they are binding on you.
              Under these Terms, “
              <span className="terms-text-bold ">Content</span>” means any
              information, text, graphics, or other materials uploaded on the
              Platform by any user of the Platform or by us.
            </p>
            <p className="terms-content-text">
              Any personal information you provide us will be dealt with in
              accordance with our Privacy Policy These Terms include our Privacy
              Policy by way of reference.
            </p>
          </div>
          <div className="terms-section-container">
            <h1 className="terms-header-text">product information</h1>
            <p className="terms-content-text">
              These Terms are compliant with the laws of India. If you wish to
              use our Platform outside India, please ensure that you are
              permitted to do so, in your jurisdiction.{" "}
            </p>
          </div>
          <div className="terms-section-container">
            <ol
              style={{ listStyleType: "number", counterReset: "item" }}
              className="terms-header-text"
            >
              <li>
                <h1 className="terms-header-text">SERVICES OFFERED</h1>
                <p className="terms-content-text terms-text">
                  We help you discover cattle traders, stay informed, seek Vet
                  consultations, and get all possible cattle related services.
                  through our Platform.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text terms-text"
                >
                  <li style={{ content: "" }}>
                    Through the Platforms, we aim to provide solutions to all
                    needs of dairy farmers. You may avail the following services
                    through our Platforms (“
                    <span className="terms-text-bold ">Services</span>”) :
                  </li>
                  <ol
                    className="terms-content-text "
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li>
                      Discover and connect with sellers and buyers of cattle;
                    </li>
                    <li>Make online payments for purchase of cattle;</li>
                    <li>
                      Book and avail online/offline consultations from certified
                      veterinarians (“Vet”) and seek information on the health,
                      disease and well-being of cattle;{" "}
                    </li>
                    <li>
                      Seek information about various goods (“Products”)
                      necessary for dairy farming including cattle feed, feed
                      supplements, mineral mixtures, and nutritional inputs;{" "}
                    </li>
                    <li>
                      Seek delivery of cattle purchased through the Platform;
                    </li>
                    <li>
                      Make use of farm management, expense management and
                      passbook tools;{" "}
                    </li>
                    <li>
                      View informative Content on cattle health, nutrition,
                      breeding, dairy farming among others; and
                    </li>
                    <li>Any other services provided on the Platform. </li>
                  </ol>
                  <br />
                  <li>
                    Services related to the health of your cattle include
                    online/offline consultations with Vets and in some cases
                    supply of Products.{" "}
                  </li>
                  <ol
                    className="terms-content-text "
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li>
                      We assess Vets on the Platform before hiring and listing
                      them. Vets are assisted by executives who are tasked with
                      onboarding and documenting your queries.{" "}
                    </li>
                    <li>
                      You may consult Vets through the Platform or through
                      third-party applications like WhatsApp.{" "}
                    </li>
                    <li>
                      We collect, directly or indirectly, and display on the
                      Platform, relevant information regarding the profile and
                      practice of the Vets, listed on the App. This information
                      includes such specialization, qualification, fees,
                      location, visiting hours, and similar details.  We take
                      reasonable efforts to ensure that such information is
                      updated at frequent intervals. Although we screen and vet
                      the information and photos submitted by the Vets, we
                      cannot be held liable for any inaccuracies or
                      incompleteness represented from it, despite such
                      reasonable efforts.
                    </li>
                  </ol>
                </ol>
              </li>
              <br />

              <li>
                <h1 className="terms-header-text">YOUR ACCOUNT</h1>
                <p className="terms-content-text terms-text">
                  Your Account will be created on the basis of the details
                  provided by you. You shall be responsible for maintaining the
                  confidentiality of your Account Information and preventing
                  unauthorized access by third parties. We can delete your
                  Account if you act in violation of these Terms.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text terms-text"
                >
                  <li style={{ content: "" }}>
                    You must be registered on the Platform to access or use the
                    Services. We will create an account for you on the Platform
                    (“<span className="terms-text-bold ">Account</span>”) upon
                    verification of your credentials.{" "}
                  </li>
                  <li>
                    The Account will be created on the basis of the details
                    provided by you which may include, without limitation, your
                    name, mobile number, your state and district of residence
                    and any other information you provide at the time of signing
                    up. You will access your Account using your mobile number
                    and OTP delivered to your mobile number (“
                    <span className="terms-text-bold ">
                      Account Information
                    </span>
                    ”).{" "}
                  </li>
                  <li>
                    You must always treat your Account Information as
                    confidential and must not disclose it to any third party.
                    Any access to the Platform through your Account shall be
                    considered an access by you or on your behalf and you shall
                    be solely responsible for any activity carried out in, by or
                    through your Account either on the Platform or any other
                    website accessed by you through the Platform. You do not
                    have the right to transfer your Account or assign the rights
                    associated with such Account to any third party, and/or
                    register an Account on the Platform on behalf of any third
                    party.
                  </li>
                  <li>
                    If we change the eligibility criteria to be registered with
                    the Platform and you no longer comply with the new
                    eligibility criteria (as determined by us in our sole
                    discretion), we may deactivate/close your Account without
                    any liability to us.{" "}
                  </li>
                  <li>
                    You are solely responsible for ensuring that these Terms are
                    in compliance with all laws, rules and regulations
                    applicable in your area of residence. Your right to access
                    the Platform is revoked where these Terms or use of the
                    Platform is prohibited and, in such circumstances, you agree
                    not to use or access the Platform in any manner.{" "}
                  </li>
                  <li>
                    Your Account may be accessed by us at any time to facilitate
                    discussions, mediate disputes, resolve technical issues or
                    for any other reason as necessary in our sole discretion.{" "}
                  </li>
                  <li>
                    We reserve the right to disable your use of the Account or
                    any part of it, at any time, if, in our reasonable opinion,
                    you have failed to comply with any of the provisions of
                    these Terms.{" "}
                  </li>
                  <li>
                    If you know or suspect that there has been a breach of
                    security of your Account, or any person has accessed your
                    Account in an unauthorized manner, you must promptly notify
                    us by sending us an e-mail at
                    <a href="mailto:contact@mooo.farm">
                      {" "}
                      <span
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        contact@mooo.farm
                      </span>{" "}
                    </a>
                    . We are not liable for any losses or other consequences of
                    unauthorised use of your Account.
                  </li>
                  <li>
                    In case you wish to deactivate your Account, please contact
                    us at{" "}
                    <a href="mailto:contact@mooo.farm">
                      {" "}
                      <span
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        contact@mooo.farm
                      </span>{" "}
                    </a>
                    . Your Account will not be deactivated unless your request
                    is accepted by us, subject to clearance of all pending dues/
                    payments by you.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">
                  USER REPRESENTATIONS & COVENANTS
                </h1>
                <p className="terms-content-text ">
                  Your usage of the Platform shall be subject to compliance with
                  the covenants under this clause and the
                  representations/warranties provided by you.
                </p>
                <p className="terms-content-text">
                  With respect to your use of the Platform or the Services, you
                  represent and warrant that:
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li style={{ content: "" }}>
                    You are legally capable of entering into a contract and are
                    at least 18 years of age;{" "}
                  </li>
                  <li>
                    You have the requisite authorizations and approvals
                    necessary for entering into and performance of these Terms;{" "}
                  </li>
                  <li>
                    There are no restrictions, hindrances, or encumbrances of
                    any nature which restrict you from performing your
                    obligations under these Terms;{" "}
                  </li>
                  <li>
                    You will comply with all applicable laws including any
                    rules, regulations and by-laws in relation to your use of
                    the Platform, our Services, and any other service provided
                    by us, our affiliates or our service providers. In
                    particular, you will ensure compliance with all applicable
                    laws in relation to the trade, storage and transport of
                    cattle.{" "}
                  </li>
                  <li>
                    All information you submit while creating your Account are
                    true, accurate, current, and complete; and{" "}
                  </li>
                  <li>
                    You have the necessary intellectual property rights
                    (including the right to license and assign) in any Content
                    or information you display, post, host or share on the
                    Platform. Such Content does not infringe any proprietary or
                    other rights of third parties or contain any libellous,
                    tortious, or otherwise unlawful information.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">Use of the Platform</h1>
                <p className="terms-content-text">
                  You are responsible for the Content posted by you on the
                  Platform and its compliance with the standards under these
                  Terms.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li style={{ content: "" }}>
                    You must not use the Platform in any way that causes, or is
                    likely to cause, the Platform or access to it to be
                    interrupted, damaged or impaired in any way. You understand
                    that you, and not we, are responsible for all electronic
                    communications and Content sent from your device to us and
                    you must use the Platform for lawful purposes only. You must
                    not use the Platform for any of the following:
                  </li>
                  <ol
                    className="terms-content-text"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li>
                      for fraudulent purposes, or in connection with a criminal
                      offense or other unlawful activity;
                    </li>
                    <li>
                      to send, use or reuse any material that does not belong to
                      you; or is illegal, offensive (including but not limited
                      to material that is sexually explicit content or which
                      promotes racism, bigotry, hatred or physical harm),
                      deceptive, misleading, abusive, indecent, insulting or
                      harassing, blasphemous, defamatory, libellous, obscene,
                      pornographic, paedophilic, or menacing; ethnically
                      objectionable, disparaging or in breach of copyright,
                      trademark, patent, confidentiality, privacy or any other
                      proprietary information or right; or is otherwise
                      injurious to third parties; or relates to or promotes
                      money laundering or gambling; or is harmful to minors in
                      any way; or impersonates another person; or threatens the
                      unity, integrity, defence, security or sovereignty of
                      India or friendly relations with foreign states or public
                      order or causes incitement to the commission of any
                      cognizable offence or prevents investigation of any
                      offence or is insulting other nation; or objectionable or
                      otherwise unlawful in any manner whatsoever; or which
                      consists of or contains software viruses or any other
                      computer code, file or program designed to interrupt,
                      destroy or limit the functionality of any computer
                      resource, political campaigning, commercial solicitation,
                      chain letters, mass mailings or any "spam; or is patently
                      false and untrue;
                    </li>
                    <li>
                      to cause annoyance, inconvenience, or needless anxiety; or
                    </li>
                    <li>
                      to give or cause to give the impression that you are
                      associated with us and/or the information you share is
                      endorsed by us.{" "}
                    </li>
                  </ol>

                  <li>
                    You agree not to access (or attempt to access) the Platform,
                    the Content or Services by any means other than through the
                    interface that is provided by us. You shall not use any
                    deep-link, robot, spider, or other automatic device,
                    program, algorithm, or methodology, or any similar or
                    equivalent manual process, to access, acquire, copy or
                    monitor any portion of the Platform or Content, or in any
                    way reproduce or circumvent the navigational structure or
                    presentation of the Platform, or any Content, to obtain or
                    attempt to obtain any materials, bookings, documents or
                    information through any means not specifically made
                    available through the Platform.{" "}
                  </li>
                  <li>
                    You must not use the Platform in any manner that could
                    damage, disable, overburden, block, or impair the Platform
                    or Services, whether in part or in full and whether
                    permanently or temporarily, or disallow or interfere with
                    any other party's use and enjoyment of the Platform or
                    Services.
                  </li>
                  <li>
                    We reserve the right, but have no obligation, to monitor the
                    Content posted on the Platform. We have the right to remove
                    or edit any Content that in our sole discretion violates, or
                    is alleged to violate, any applicable law or these Terms.
                    Notwithstanding this right, you remain solely responsible
                    for the Content you post on the Platform. The views
                    expressed by you or any other user on the Platform do not
                    represent our views or values.{" "}
                  </li>
                  <li>
                    We have the right at all times to disclose any information
                    or Content (including the identity of the persons uploading
                    or hosting such Content) as necessary to satisfy any law,
                    regulation, valid governmental request or as necessary in
                    our opinion for the resolution of a criminal offence.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">
                  Transactions Between users
                </h1>
                <p className="terms-content-text">
                  We are not parties to your transactions with other users. You
                  must do your due diligence before entering into such
                  transactions.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li style={{ content: "" }}>
                    The Platform acts as an intermediary and allows users to
                    interact with other user(s) for entering into negotiations
                    in respect thereof for purchase, sale or supply of cattle.
                    We are not parties to any negotiations that take place
                    between the users. Nor are we parties to any agreement
                    including an agreement for purchase, sale or supply of
                    cattle, concluded between the users.
                  </li>
                  <li>
                    We do not control and are not liable in respect of or
                    responsible for the quality, age, health, lactation
                    capacity, or any other information about the cattle offered
                    for sale by users on the Platform. Nor do we warrant the
                    ability of the users selling cattle to complete a sale or
                    the ability of users purchasing cattle to complete a
                    purchase. You must conduct your due diligence before making
                    a purchase from any user.{" "}
                  </li>
                  <li>
                    We do not represent either Party in transactions between
                    users and do not charge any commission for enabling such
                    transaction. However, you acknowledge that we may levy
                    charges for facilitating such transactions through the
                    Platform in the future.{" "}
                  </li>
                  <li>
                    Transactions, transaction price, and all commercial terms
                    are as per principal to principal bipartite contractual
                    obligations between the users involved. We provide the
                    payment facility merely to facilitate the completion of the
                    transaction. Use of the payment facility does not render us
                    liable or responsible for the non-delivery, non-receipt,
                    non-payment, damage, breach of representations and
                    warranties, or fraud as regards the cattle listed on the
                    Platform by users.
                  </li>
                  <li>
                    You specifically authorize us and our service providers to
                    collect, process, facilitate and remit payments and/or the
                    transaction price electronically to and from other users in
                    respect of transactions. Your relationship with us is on a
                    principal to principal basis and by accepting these Terms,
                    you agree that we are an independent contractor for all
                    purposes, and do not have control of or liability for the
                    cattle listed by users on the Platform. We do not guarantee
                    the identity of any user nor do we ensure that a buyer or a
                    seller will complete a transaction.
                  </li>
                  <li>
                    You agree that we shall not be parties to any disputes
                    between users.{" "}
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">Transactions with us </h1>
                <p className="terms-content-text">
                  You must satisfy yourself of the cattle before entering into a
                  cattle transaction with us. You should use only the authorised
                  payment channels for payment.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li style={{ content: "" }}>
                    Where you enter into a transaction with us, such a
                    transaction is entered into on a principal-to-principal
                    basis between the parties.{" "}
                  </li>
                  <li>
                    We strive to be as accurate as possible in the description
                    of all our cattle listed on the Platform. But except as
                    expressly stated otherwise, we do not warrant that the
                    information about our cattle on the Platform is complete or
                    current. The Platform may contain typographical errors. The
                    pictures of the cattle are indicative and may not match the
                    actual cattle. We reserve the right to correct, change or
                    update information, errors, inaccuracies, or omissions at
                    any time without prior notice.
                  </li>
                  <li>
                    Upon entering into a transaction for purchase or sale of
                    cattle with us, you are deemed to have satisfied yourself of
                    the quality, suitability, fitness, and any factors relevant
                    to your decision to enter into a transaction with us.{" "}
                  </li>
                  <li>
                    By entering into a transaction for purchase of cattle with
                    us, you represent and warrant that such cattle for your
                    personal use only and will not be sold, resold, or utilized
                    for slaughter.
                  </li>
                  <li>
                    You specifically authorize us and our service providers to
                    collect, process, facilitate and remit payments and/or the
                    transaction price electronically to and from other users in
                    respect of transactions. Your relationship with us is on a
                    principal to principal basis and by accepting these Terms,
                    you agree that we are an independent contractor for all
                    purposes, and do not have control of or liability for the
                    cattle listed by users on the Platform. We do not guarantee
                    the identity of any user nor do we ensure that a buyer or a
                    seller will complete a transaction.
                  </li>
                  <li>
                    You acknowledge that we reserve the sole right to revise the
                    prices of our cattle on the Platform. Further, the displayed
                    price may exclude delivery and conveyance charges, and/or
                    applicable taxes. Before completing a transaction, we will
                    inform you of the total price and obtain your consent for
                    the same.{" "}
                  </li>
                  <li>
                    The title in cattle you purchase from us, will pass on to
                    you: (i) immediately, when the possession is physically
                    handed over; or (ii) upon us delivering the cattle to our
                    delivery partner for delivery to your designated address, as
                    the case may be. Likewise, in the event of a sale of cattle
                    to us, the title in cattle will pass on to us: (i)
                    immediately, when the possession is physically handed over
                    to us; or (ii) upon you delivering the cattle to our
                    delivery partner for delivery to our designated address, as
                    the case may be.
                  </li>
                  <li>
                    You will make and receive the payment for cattle
                    transactions only using the payment channels authorised by
                    us. Payments made through any other channels shall not be
                    considered a valid discharge of your payment obligations.{" "}
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">MoooCoins</h1>
                <p className="terms-content-text">
                  We may issue reward points to some eligible users. You must
                  use these reward points within the expiry date.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li style={{ content: "" }}>
                    We may, at our sole discretion, issue reward points
                    (“Mooocoins”) to certain eligible users of the Platform. You
                    acknowledge that Mooocoins cannot be purchased and can only
                    be earned as rewards.{" "}
                  </li>
                  <li>
                    You may use Mooocoins for making payments to us for the
                    Services only. You agree that we may limit the amount of
                    Mooocoins which may be used in a single transaction.{" "}
                  </li>
                  <li>
                    The present form and format of Mooocoins, issued by us, is
                    modelled on a closed system prepaid payment instrument.
                    Mooocoins are not, nor are intended to be a licensed payment
                    system under the Payment and Settlement Systems Act, 2007.{" "}
                  </li>
                  <li>
                    Mooocoins are not refundable or redeemable into fiat
                    currency. On cancellation of bookings paid for through
                    Mooocoins, the said Mooocoins will be credited back to the
                    user’s Account upon deducting applicable charges.{" "}
                  </li>
                  <li>
                    We reserve the right to set an expiry date for Mooocoins.
                    Beyond the expiry date, Mooocoins will not be available for
                    use.{" "}
                  </li>
                  <li>
                    We reserve the sole right to determine and modify the value
                    at which each Mooocoin is exchangeable.{" "}
                  </li>
                  <li>
                    Mooocoins are non-transferable and may only be used by the
                    person who holds the Account associated with such Mooocoins.
                  </li>
                  <li>
                    We reserve the right to discontinue Mooocoins on our
                    Platform at any time, in our sole discretion. In the event
                    of discontinuation, the Mooocoins associated with your
                    Account will lapse without giving rise to any claim.{" "}
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">PAYMENT TERMS</h1>
                <p className="terms-content-text">
                  We use the services of third parties to facilitate payments.
                  And may need to store details of your payment instruments.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    Registration on the Platform is free and we do not charge
                    any fee for browsing the Platform. We reserve the right to
                    charge a fee for accessing the Platform at any time in the
                    future. However, the Services facilitated through the
                    Platform are subject to the fee as provided on the Platform.{" "}
                  </li>
                  <li>
                    All payments made by you on the Platform must be in Indian
                    Rupees acceptable in the Republic of India. The Platform
                    will not facilitate transactions with respect to any other
                    form of currency with respect to the payments made on
                    Platform.
                  </li>
                  <li>
                    We reserve the right to impose and change the fees payable
                    by the users at our sole discretion for availing the
                    Services on the Platform. You shall pay the amount due for
                    the Services rendered to you through the payment methods
                    offered on the Platform, including without limitation, net
                    banking, credit card, debit card or electronic wallet. By
                    making a payment through us, you undertake that the credit
                    or debit card details or third-party electronic wallet login
                    credentials that you provide are for your own credit card,
                    debit card, or electronic wallet account, and that you have
                    sufficient funds to make the payment for the Services.{" "}
                  </li>
                  <li>
                    You understand, accept and agree that the payment facility
                    provided by us is neither a banking nor financial service
                    but is merely an electronic payment, collection and
                    remittance facility for completing transactions using the
                    existing authorized banking infrastructure and payment
                    services. By providing the payment facility, we are neither
                    acting as trustees nor acting in a fiduciary capacity with
                    respect to the transaction or the transaction price.
                  </li>
                  <li>
                    In connection with your use of the Services, we will obtain
                    certain transaction and payment instrument details, which we
                    will use solely in accordance with our Privacy Policy.{" "}
                  </li>
                  <li>
                    Except to the extent otherwise required by applicable law,
                    we are not liable for any payments authorized through the
                    Platform using your payment details. Particularly, we are
                    not liable for any payments that do not complete because:
                    (1) your payment card or bank account or third party wallet
                    does not contain sufficient funds to complete the
                    transaction or the transaction exceeds the credit limit in
                    respect of the payment details provided; (2) you have not
                    provided us with correct details; (3) your payment card has
                    expired; or (4) circumstances beyond our control (such as,
                    but not limited to, power outages, interruptions of cellular
                    service, or any other interferences from an outside force)
                    prevent the execution of the transaction.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">
                  INTELLECTUAL PROPERTY RIGHTS
                </h1>
                <p className="terms-content-text">
                  Please respect our intellectual property and do not use it in
                  an unauthorized manner without our prior consent. You give us
                  a license to reproduce, process and publish the Content posted
                  by you on the Platform for marketing and promotional
                  activities.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    We or our affiliates or licensors own all right, title, and
                    interest in and to the Platform and Services, all Content
                    therein (except Content of users) and all related technology
                    and intellectual property rights. Subject to these Terms and
                    on payment of applicable fee, we grant you a limited,
                    revocable, non-exclusive, non-sublicensable,
                    non-transferrable license (“Limited License”) to do the
                    following: (a) access and use the Platform and Services
                    solely in accordance with these Terms; and (b) copy, use and
                    share the Content on the Platform solely in connection with
                    your permitted use of the Services. Except as provided in
                    this Clause 9.1 (Intellectual Property Rights), you obtain
                    no intellectual property rights under these Terms from us,
                    our affiliates or our licensors to the Platform or Services,
                    including any related intellectual property rights.
                  </li>
                  <li>You may not without our express written consent:</li>
                  <ol
                    className="terms-content-text"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li>
                      systematically extract/ or re-utilise parts of the Content
                      of the Platform without our express written consent;{" "}
                    </li>
                    <li>
                      create and/ or publish your own database that features
                      substantial parts of this Platform; or
                    </li>
                    <li>
                      make any commercial or derivative use of the Platform or
                      its Contents, any collection and use of any cattle
                      listings, descriptions, or prices.
                    </li>
                  </ol>
                  <li>
                    When you upload or post Content on the Platform, you grant
                    us a perpetual, worldwide, non-exclusive, royalty-free
                    license (with the right to sublicense it to our affiliates
                    for the purposes of marketing and promotional activities) to
                    use, copy, reproduce, process, adapt, modify, publish,
                    transmit, display and distribute such Content in any and all
                    media or distribution methods. We may adapt the form of the
                    Content in order to transmit, display or distribute it on
                    the Platform and in various media and/or make changes to the
                    Content as are necessary to conform and adapt that Content
                    to any requirements or limitations of any networks, devices,
                    services, or media. We reserve the right to disclose your
                    identity to any third party who is claiming that the Content
                    posted or uploaded by you to the Platform constitutes a
                    violation of intellectual property rights, or right to
                    privacy of such third party.{" "}
                  </li>
                  <li>
                    If you provide any suggestions to us or our affiliates for
                    improvement of the Platform and Services, we or our
                    affiliates will be entitled to use such suggestions without
                    restriction. You hereby irrevocably assign to us all right,
                    title, and interest in and to such suggestions and agree to
                    provide us or our affiliates any assistance we require to
                    document, perfect, and maintain our rights in such
                    suggestions.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">DISCLAIMERS</h1>
                <p className="terms-content-text">
                  We are not responsible for the accuracy, availability and
                  reliability of the Platform, Services and the Content. Any
                  Content posted by the users on the Platform is the sole
                  responsibility of such users, and we shall not be liable with
                  respect to any interactive services provided on the Platform.
                  Any information on this Platform is for informational purposes
                  only.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    You expressly acknowledge and agree that use of the Platform
                    and the Services is at your sole risk. The Platform and the
                    Services are provided on an "as is" and "as available"
                    basis. To the fullest extent allowed by law, we expressly
                    disclaim all warranties of any kind, whether express or
                    implied, including, but not limited to the warranties of
                    merchantability or fitness for a particular purpose. We make
                    no warranty that the Platform or the Services will meet your
                    requirements, or that the Services or your access to the
                    Platform will be uninterrupted, timely, accurate or
                    reliable, nor do we make any warranty as to any information
                    that may be obtained through the Platform or the Services.
                    In case there is any defect in any software being used for
                    the provision of the Services, we do not make any warranty
                    that defects in such software will be corrected. You
                    understand and agree that any material and/or data
                    downloaded or otherwise obtained through use of the
                    Platform, or the Services is done at your own discretion and
                    risk, and you will be solely responsible for any damage to
                    your computer system or loss of data that results from the
                    download of such material or data.{" "}
                  </li>
                  <li>
                    We may, without prior notice, change the Platform and/or the
                    Services; add or remove functionalities or features; stop
                    providing the Platform and/or the Services or certain
                    features, to you or to users generally; and/or create usage
                    limits for the Services.{" "}
                  </li>
                  <li>
                    We provide an online platform for exchanging information
                    between you and other users. We do not represent any party
                    in a specific transaction between the users and merely act
                    as intermediaries. All transactions between users for
                    purchase of cattle are on a principal-principal basis.{" "}
                  </li>
                  <li>
                    The Platform and/or Services may contain bugs, errors,
                    problems, or other limitations. We assume no liability or
                    responsibility for any errors or omissions in the Platform
                    and/or Services.
                  </li>
                  <li>
                    Unless specifically stated otherwise, we do not warrant that
                    any part of Content is accurate, complete, reliable,
                    current, or error-free. The pictures of the cattle on the
                    Platform may not match the actual cattle. We reserve the
                    right to correct, change or update information, errors,
                    inaccuracies, or omissions at any time without prior notice.
                    We shall not bear any liability for any loss/ injury that
                    may arise due to your reliance on the Content published on
                    the Platform.{" "}
                  </li>
                  <li>
                    We are not responsible for the Content uploaded by you or
                    other users on the Platform. We are not responsible for any
                    direct or indirect damages or losses caused to you,
                    including without limitation, lost profits, business
                    interruption or other loss resulting from use of or reliance
                    in any way on anything available on the Platform. It is
                    solely your responsibility to evaluate the accuracy,
                    reliability, completeness, and usefulness of Content
                    available on the Platform. Please note that any of the
                    Content on the Platform may be out of date at any given time
                    and we are under no obligation to update it. We do not
                    guarantee that the Platform or any Content on it, will be
                    free from errors or omissions.
                  </li>
                  <li>
                    You acknowledge and agree that we are not responsible for
                    the advice, care, diagnosis, and treatment plans provided by
                    Vets for your cattle. We merely provide a technology
                    platform through which you may book appointments and avail
                    services from Vets.{" "}
                  </li>
                  <li>
                    You acknowledge and agree that we will not be liable for
                    deficiency or shortfall in Services / misdiagnosis / faulty
                    judgment / interpretation error / perception error / adverse
                    events / inefficacy of prescribed treatment or advice or
                    investigation reports / validity of the advice or
                    prescription or investigation reports provided by the Vet /
                    unavailability of the recommended or prescribed treatment or
                    medication under any condition or circumstances. You are
                    advised to use your discretion for following the advice
                    obtained after consultation with Vet via the Platform or
                    after availing the Services.
                  </li>
                  <li>
                    Some of the content, text, data, graphics, images,
                    information, suggestions, guidance, and other material that
                    may be available on the Platform (including information
                    provided in direct response to your questions or postings)
                    may be provided by Vets). You acknowledge and agree that
                    this content does not create a licensed medical
                    professional/patient relationship, and does not constitute
                    an opinion, medical advice, or diagnosis or treatment of any
                    particular condition, but is only provided to assist you
                    with locating appropriate veterinarian care from a qualified
                    practitioner.{" "}
                  </li>
                  <li>
                    You acknowledge and agree that the Vet’s care of your cattle
                    depends on the information provided by you. You will provide
                    accurate and complete information on the Platform, based on
                    which you will receive the Services. You are solely
                    responsible for the medical, health information you provide
                    on the Platform.
                  </li>
                  <li>
                    You acknowledge and agree that we do not provide emergency
                    medical services through the Platform. In case of a medical
                    emergency, please contact an ambulance service, hospital,
                    veterinarian, or appropriate professional directly.
                  </li>
                  <li>
                    No advice or information, whether oral or written, obtained
                    by you from Platform or through the Services shall create
                    any warranty not expressly made herein.
                  </li>
                  <li>
                    We may from time to time provide interactive services on the
                    Platform. We will do our best to assess any possible risks
                    for users when they use any interactive services provided on
                    the Platform. However, we are under no obligation to
                    oversee, monitor or moderate any interactive services we
                    provide on the Platform.{" "}
                  </li>
                  <li>
                    We assume no responsibility for the third-party websites
                    linked on the Platform. Such links should not be interpreted
                    as our endorsement of those linked websites. We will not be
                    liable for any loss or damage that may arise from your use
                    of them. You are advised to carefully read the terms
                    applicable to your use of such third-party websites.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">
                  INDEMNIFICATION AND LIMITATION OF LIABILITY
                </h1>
                <p className="terms-content-text">
                  You are under the duty to indemnify us if you breach these
                  Terms or applicable law. Our liability for unavailability of
                  the Platform or your reliance on Content is limited.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    You will defend, indemnify, and hold harmless us, our
                    affiliates and licensors, and each of their respective
                    employees, officers, directors, and representatives (“
                    <span className="terms-text-bold ">
                      Indemnified Parties
                    </span>
                    ”) from and against any Losses arising out of or relating to
                    any third party claim concerning: (a) your use of the
                    Platform or Services (including any activities under your
                    Account); (b) breach of these Terms or violation of
                    applicable law by you, your Content; or (c) a dispute
                    between you and any other user. The term (“
                    <span className="terms-text-bold ">Losses</span>”) wherever
                    used in these Terms means any direct, indirect or
                    consequential claims, damages, losses, liabilities, costs,
                    and expenses (including reasonable attorneys’ fees), whether
                    in contract, tort (including negligence), breach of
                    statutory duty, or otherwise.
                  </li>
                  <li>
                    {" "}
                    You agree to hold the Indemnified Parties harmless for
                    Losses arising from Services of a medical nature. This
                    includes, but is not limited to, Losses arising from:
                    <ol
                      className="terms-content-text"
                      style={{ listStyleType: "lower-alpha" }}
                    >
                      <li>your interactions with Vets or their staff;</li>
                      <li>
                        the quality of healthcare, drugs, or treatment
                        prescribed by Vets;{" "}
                      </li>
                      <li>pre-existing conditions in the cattle; </li>
                      <li>
                        sudden deterioration of medical condition of the cattle
                        resulting from your omission of relevant information
                        regarding the health the cattle, at the time of
                        treatment;{" "}
                      </li>
                      <li>
                        adverse reaction(s) of the cattle to drugs/treatments
                        prescribed by Vets;{" "}
                      </li>
                      <li>
                        inappropriate behaviour or conduct of Vets or their
                        staff;{" "}
                      </li>
                      <li>
                        no-shows, cancellations, or delays in calls or visits by
                        the Vets or their staff; and,
                      </li>
                      <li>
                        the quality or efficacy of cattle feed, feed
                        supplements, mineral mixtures, and nutritional inputs.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Indemnified Parties shall not be liable to any user for
                    any Losses arising under or in connection with use of, or
                    inability to use the Platform and/or Services; use of or
                    reliance on any Content displayed on the Platform;
                    unauthorised access to Account; loss of profits, sales,
                    business, business opportunity or revenue. In any case, the
                    Indemnified Parties’ aggregate liability under these Terms
                    will not exceed the lowest permissible amount under
                    applicable law or INR 1000, whichever is lower.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">
                  Returns and Refunds; Disputes between Users
                </h1>
                <p className="terms-content-text">
                  All refunds, returns and cancellations are subject to our
                  Returns and Cancellation Policy. If there is a dispute between
                  you and other users, please remember that we will not be a
                  party to your arrangement. As much as we may want to help you,
                  our role will be limited.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    All refunds, cancellations, and returns are subject to our
                    Returns and Cancellation Policy.{" "}
                  </li>
                  <li>
                    We will not be parties to any disputes that may arise
                    between you and other users under the terms of your
                    agreement. However, if requested in writing and on such
                    request being found reasonable, in our sole discretion, you
                    may contact us at{" "}
                    <a href="mailto:contact@mooo.farm">
                      {" "}
                      <span
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        contact@mooo.farm
                      </span>{" "}
                    </a>
                    and we may provide assistance to the parties involved in the
                    dispute.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">Shipping and Delivery</h1>
                <p className="terms-content-text">
                  We endeavour to deliver your orders in time with the help of
                  our delivery partners.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    If you choose to avail delivery services from us, the terms
                    under this Clause 13 (Shipping and Delivery) shall apply.{" "}
                  </li>
                  <li>
                    You agree to pay the applicable fee for the delivery
                    services provided by us, whether ourselves or through our
                    delivery partners. You acknowledge that we will not be able
                    to service your delivery in the event of non-payment of
                    applicable fee.{" "}
                  </li>
                  <li>
                    We use third party delivery partners to deliver cattle to
                    you at the address provided by you. You will be provided
                    with details of the delivery partner and tracking details
                    via e-mail to enable you to track your order. In case they
                    are unable to reach your specified delivery location, they
                    may contact you to resolve the issue.
                  </li>
                  <li>
                    Our third-party delivery partners will deliver the cattle to
                    you in accordance with the timeline mentioned at the time of
                    making the purchase. The delivery time can be tracked with
                    the details provided by our delivery partners. These dates,
                    however, may be changed by our delivery partners, upon the
                    occurrence of force majeure events.
                  </li>
                  <li>
                    You will be informed of the applicable shipping fee at the
                    time of making a purchase. We will provide you delivery
                    services only upon your confirmation of the shipping fee.{" "}
                  </li>
                  <li>
                    Us and our delivery partners take care to ensure that the
                    cattle are transported safely. It is your responsibility to
                    inspect the cattle upon delivery to ensure that they are in
                    the same state as at the time of order.
                  </li>
                  <li>
                    We require you to provide accurate details, phone number and
                    address for us to be able to deliver your order. In case you
                    provide us with inaccurate details for delivery, we will be
                    unable to deliver your order. We, the delivery partners and
                    all our affiliates, will not be responsible in any manner
                    for being unable to deliver your order due to any such
                    inaccurate details provided by you. Additionally, if you
                    provide us with an incorrect delivery address or misuse the
                    Platform, then we may blacklist you and bar you from using
                    the Platform.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">TERM & TERMINATION</h1>
                <p className="terms-content-text">
                  We will be sad when you leave the Platform, but we will assist
                  you to make the process easy.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    We reserve the right to immediately discontinue your access
                    to this Platform and/or the Services at any time, with or
                    without cause. These Terms shall be deemed automatically
                    terminated on the occurrence of such event.{" "}
                  </li>
                  <li>
                    You may terminate these Terms for any reason by providing us
                    a 30 days’ prior notice and closing your Account through our
                    account closing mechanism
                  </li>
                  <li>
                    The Terms shall continue to form a valid and binding
                    contract between us and shall continue to be in full force
                    and effect till the time you continue accessing and using
                    the Platform.{" "}
                  </li>
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Effect of Termination:
                    </span>
                    <ol
                      className="terms-content-text"
                      style={{ listStyleType: "lower-alpha" }}
                    >
                      <li>
                        except as provided in this Clause 14.4 (Effect of
                        Termination), all your rights under these Terms
                        immediately terminate;
                      </li>
                      <li>
                        you remain responsible for all fees and charges you have
                        incurred until the date of termination;
                      </li>
                      <li>
                        we will aim to complete any ongoing transactions with us
                        on a good faith basis;{" "}
                      </li>
                      <li>
                        Notwithstanding anything to the contrary in these Terms,
                        Clauses 4.1 (Use of the Platform), 5 (Transactions
                        Between Users), 6 (Transactions with Us), 8 (Payment
                        Terms), 9 (Intellectual Property Rights), 10
                        (Disclaimers), 11 (Indemnification and Limitation of
                        Liability), 15 (General Terms) and this Clause 14.4
                        (Effect of Termination) will continue to apply in
                        accordance with their terms.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">GENERAL TERMS </h1>
                <p className="terms-content-text">
                  These general terms are for you to understand these Terms
                  better. Make sure your take a look at what they say.
                </p>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Electronic communication
                    </span>
                    : When you visit this Platform, or send e-mails to us, you
                    are communicating with us electronically. You consent to
                    receiving communications from us electronically. We will
                    communicate with you by e-mail or by posting notices on this
                    Platform. In case there are any changes to any information
                    provided by you to us, including your e-mail address and
                    other contact details, you will be solely responsible to
                    update them regularly. If we send any communication by
                    e-mail, it shall be sent to your e-mail address available on
                    the records created by you on the Platform and it shall be
                    deemed to have been received by you once it is reflected as
                    sent in the outbox of our e-mail id.
                  </li>
                  <br />
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Relationship of the Parties
                    </span>
                    : We and you are independent contractors, and these Terms
                    will not be construed to create a partnership, joint
                    venture, agency, or employment relationship. Neither party,
                    nor any of their respective affiliates, is an agent of the
                    other for any purpose or has the authority to bind the
                    other.
                  </li>
                  <br />
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Dispute Resolution
                    </span>
                    : These Terms and any dispute or claim arising out of or in
                    connection with it or its subject matter, interpretation,
                    validity, existence or formation (including non-contractual
                    disputes or claims) (“
                    <span className="terms-text-bold ">Dispute</span>”) shall be
                    governed by and construed in accordance with the laws of
                    India. The aggrieved party shall notify the other party
                    about the existence and nature of the Dispute by serving a
                    written notice (the “
                    <span className="terms-text-bold ">Dispute Notice</span>”) .
                    Upon receipt of the Dispute Notice, the parties hereto shall
                    endeavour to settle such Dispute amicably. If the Dispute
                    has not been amicably resolved within fifteen (15) days from
                    receipt of the Dispute Notice, the Dispute shall be referred
                    to and finally resolved by arbitration in accordance with
                    the fast-track provisions of the Indian Arbitration and
                    Conciliation Act, 1996 as last amended. The arbitral
                    tribunal shall consist of one (1) arbitrator mutually
                    appointed by the parties. The seat of arbitration shall be
                    in Gurugram, India. The award and decision of the arbitrator
                    shall be final, binding and conclusive on the parties. The
                    language of the arbitration proceedings shall be English.
                    Each party shall bear its own expenses and costs in relation
                    to the arbitral proceedings contemplated under this clause.
                  </li>
                  <br />
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Applicable Law
                    </span>
                    : These Terms, its subject matter and its formation, are
                    governed by the laws of the Republic of India. Subject to
                    Clause 15.3, the courts in New Delhi, India will have
                    exclusive jurisdiction to deal with any dispute arising out
                    of or in connection with these Terms.
                  </li>
                  <br />
                  <li>
                    <span style={{ textDecoration: "underline" }}>Waiver</span>:
                    The failure by us to enforce any provision of these Terms
                    will not constitute a present or future waiver of such
                    provision nor limit our right to enforce such provision at a
                    later time. All waivers by us must be in writing to be
                    effective.
                  </li>
                  <br />
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Invalidity of Specific Terms
                    </span>{" "}
                    : If any portion of these Terms is held to be invalid or
                    unenforceable, the remaining portions of these Terms will
                    remain in full force and effect. Any invalid or
                    unenforceable portions will be interpreted to effect and
                    intent of the original portion. If such construction is not
                    possible, the invalid or unenforceable portion will be
                    severed from these Terms but the rest of these Terms will
                    remain in full force and effect.
                  </li>
                  <br />
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Force Majeure
                    </span>
                    : We and our affiliates will not be liable for any delay or
                    failure to perform any obligation under these Terms where
                    the delay or failure results from any cause beyond our
                    reasonable control, including acts of God, labour disputes
                    or other industrial disturbances, pandemics, epidemics,
                    government imposed lockdowns, electrical or power outages,
                    utilities or other telecommunications failures, earthquake,
                    storms or other elements of nature, blockages, embargoes,
                    riots, acts or orders of government, acts of terrorism, or
                    war.
                  </li>
                  <br />
                  <li>
                    <span style={{ textDecoration: "underline" }}>
                      Entire Agreement
                    </span>
                    :These Terms including the policies incorporated here by
                    reference is the entire agreement between you and us
                    regarding the subject matter of these Terms. These Terms
                    supersede all prior or contemporaneous representations,
                    understandings, agreements, or communications between you
                    and us, whether written or verbal, regarding the subject
                    matter of these Terms. If these Terms are inconsistent with
                    the terms contained in any policy issues by us, the terms
                    contained in such policy shall prevail.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">PROMOTION & DISCOUNTS </h1>
                <ol
                  style={{ listStyleType: "number", counterReset: "item" }}
                  className="terms-content-text"
                >
                  <li>
                    We may, from time to time, launch some promotions and
                    discount offers on the Services and some users might receive
                    additional benefits due to entitlement to such promotion and
                    discounts. No claims from other users seeking such
                    privileges shall lie if they are not entitled for the
                    discounts or eligible under the promotion.
                  </li>
                  <li>
                    We retain the sole right to withdraw such a promotion or
                    discount scheme or discount at any time.
                  </li>
                </ol>
              </li>
              <br />
              <li className="terms-text">
                <h1 className="terms-header-text">CONTACT US </h1>
                <p className="terms-content-text">
                  If you have any general queries or feedback relating to the
                  Platform, or come across any abuse or violation of the Terms,
                  please email us at
                  <a href="mailto:contact@mooo.farm">
                    {" "}
                    <span
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      contact@mooo.farm
                    </span>{" "}
                  </a>
                  or write to our Grievance Officer at the following address:
                </p>
                <p className="terms-content-text">Name: Aashna Singh</p>
                <p className="terms-content-text">Phone: +91-8559001001</p>
                <p className="terms-content-text">Email: contact@mooo.farm</p>
                <p className="terms-content-text">Timings: 8 am – 8 pm IST</p>
                <p className="terms-content-text">
                  We are available to assist you in case you have any questions
                  or concerns.
                </p>
              </li>
            </ol>
            <br />
            <div style={{ textAlign: "center" }}>
              <span className="terms-text-bold ">
                THANK YOU FOR VISITING US.
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsAndConditions
